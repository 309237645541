import { HOST } from '../config';
import {
    httpGet,
    httpPost,
    getAuthData,
    httpPut,
} from '../contexts/AppContext';
import { EmptyResponse } from '../models/generalModels';
import { User, AuthData, Producer, Technician, UserPerms, Division, DivisionUser } from '../models/organizationModels';

const URL_LOGIN = "auth/token/pair";
const URL_REFRESH = "auth/token/refresh";
const URL_USER = 'org/user/info';
const URL_PERMS = 'org/user/allows';
const URL_CHANGE_PASSWORD = 'org/user/changePassword';
const URL_PRODUCER = 'org/producer';
const URL_PRODUCER_BY_TECHNICIAN = 'org/producerByTechnician';
const URL_TECHNICIAN = 'org/technician';
const URL_ALL_TECHNICIAN = 'org/technicianByUser';
const URL_DIVISION = 'org/division';
const URL_DIVISION_USER = 'org/divisionUser';

export async function login(username:string , pass: string): Promise<AuthData> {
    return httpPost<AuthData>(HOST + URL_LOGIN, {username: username, password: pass});
}

export async function refreshToken(): Promise<AuthData> {
    return httpPost<AuthData>(HOST + URL_REFRESH, {
        "grant_type": "refresh_token",
        "refresh": getAuthData().refresh + "d"
    });
}

export async function getUser(): Promise<User> {
    return httpGet<User>(HOST + URL_USER);
}

// Producer
export async function getAllProducers(params?: any): Promise<Producer[]> {
    
    return httpGet<Producer[]>(HOST + URL_PRODUCER, params);
}

export async function getProducersByTechnician(params?: any): Promise<Producer[]> {
    
    return httpGet<Producer[]>(HOST + URL_PRODUCER_BY_TECHNICIAN, params);
}

// Technician
export async function getAllTechnicians(params?: any): Promise<Technician[]> {
    return httpGet<Technician[]>(HOST + URL_TECHNICIAN, params);
}

export async function getTechniciansByUser(params?: any): Promise<Technician[]> {
    return httpGet<Technician[]>(HOST + URL_ALL_TECHNICIAN, params);
}

// Division
export async function getAllDivisions(params?: any): Promise<Division[]> {
    return httpGet<Division[]>(HOST + URL_DIVISION, params);
}

export async function getDivisionUserList(params?: any): Promise<DivisionUser[]> {
    return httpGet<DivisionUser[]>(HOST + URL_DIVISION_USER, params);
}

export async function getUserPerms(): Promise<UserPerms> {
    return httpGet<UserPerms>(HOST + URL_PERMS)
}

export async function changePassoword(password: String): Promise<EmptyResponse> {
    const body = { password }
    const res = httpPut<EmptyResponse>(HOST + URL_CHANGE_PASSWORD, body);
    return res;
}